import Image from 'next/image';
import mapCdnToImgix from './helpers/mapCdnToImgix';
import ImgProps from './ImgProps';

const Img: FC<ImgProps> = (props) => {
  const [, src] = props.src.split('//');

  if (!src) {
    return null;
  }

  const url = new URL(`https://${mapCdnToImgix(src)}`);
  if (props.mask) {
    url.searchParams.set('mask', props.mask);
  }
  if (props.mono) {
    url.searchParams.set('mono', props.mono);
  }
  if (props.width) {
    url.searchParams.set('w', props.width.toString());
  }
  if (props.height) {
    url.searchParams.set('h', props.height.toString());
  }
  if (props.fit) {
    url.searchParams.set('fit', props.fit);
  }
  if (props.crop) {
    url.searchParams.set('crop', props.crop);
  }
  if (props.dpr) {
    url.searchParams.set('dpr', props.dpr.toString());
  }
  if (props.mark) {
    url.searchParams.set('mark', props.mark);
    url.searchParams.set('mark-pad', '0');
    url.searchParams.set('mark-scale', '100');
  }
  return <Image {...props} src={url.href} />;
};

export default Img;
