import { ImageLoader } from 'next/image';

const imgixLoader: ImageLoader = ({ src, width, quality = 50 }) => {
  const url = new URL(src);
  const params = url.searchParams;
  params.set('auto', 'compress,format');
  params.set('fit', params.get('fit') || 'max');
  params.set('w', params.get('w') || width.toString());
  params.set('q', quality.toString());
  return url.href;
};

export default imgixLoader;
