// ⚠️⚠️⚠️⚠️ DO NOT EDIT ⚠️⚠️⚠️⚠️
/* eslint-disable */

import type * as Types from '../../schema';

import fetchGraphql from '@/client/helpers/fetchMutation';
import gql from '@/shared/utils/gql';
import { CountryInfoFragmentDoc, ProfileFragmentDoc, UserFragmentDoc } from '../../server/graphql/getUser/UserQuery.generated';
export type UpdateUserSettingsMutationVariables = Types.Exact<{
  value: Types.Scalars['String']['input'];
  name: Types.UserSettingName;
  site?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type UpdateUserSettingsMutation = { __typename?: 'Mutation', userManagementUpdateUserSettings?: { __typename?: 'UserManagementUpdateUserSettingsMutationPayload', userManagement?: { __typename?: 'UserManagement', id: string, user?: { __typename: 'UserManagementUser', id: string, email?: string, username?: string, signupTime?: number, authMethods?: Array<string> }, profile?: { __typename: 'UserManagementProfile', _id?: string, id: string, firstName?: string, lastName?: string, maidenName?: string, email?: string, country?: string, phone?: string, affiliateID?: string, city?: string, zip?: string, house?: string, address?: string, state?: string, dateOfBirth?: number, language?: string, hasOtp?: boolean, hasSMS2FA?: boolean, emailVerified?: boolean, passwordChangeDate?: number, contactsRequestedTime?: number, lineId?: string, conversionCurrency?: string, telegramId?: string, allowUsernameChange?: boolean, settings?: { __typename?: 'UserManagementSettings', siteTheme?: string, wallets?: string, loyaltyLandingDisabled?: string, loyaltyLevelNotfDisabled?: string, gameNamesInEnglish?: string, verifyEmailClosed?: string, rtpLiveOnboardingClosed?: string, preferredInGameCurrency?: string, inGameCurrencyOnboardingClosed?: string, hasVisitedBCIO?: string } }, countryInfo?: { __typename: 'UserManagementCountryInfo', id: string, countryCode?: string, blocked?: boolean } }, errors?: Array<{ __typename?: 'UserManagementError', message?: string, params?: Array<{ __typename?: 'UserManagementErrorParam', name?: string, value?: string }> }> } };

export type ErrorsFragment = { __typename?: 'UserManagementError', message?: string, params?: Array<{ __typename?: 'UserManagementErrorParam', name?: string, value?: string }> };

export type UserManagementFragment = { __typename?: 'UserManagement', id: string, user?: { __typename: 'UserManagementUser', id: string, email?: string, username?: string, signupTime?: number, authMethods?: Array<string> }, profile?: { __typename: 'UserManagementProfile', _id?: string, id: string, firstName?: string, lastName?: string, maidenName?: string, email?: string, country?: string, phone?: string, affiliateID?: string, city?: string, zip?: string, house?: string, address?: string, state?: string, dateOfBirth?: number, language?: string, hasOtp?: boolean, hasSMS2FA?: boolean, emailVerified?: boolean, passwordChangeDate?: number, contactsRequestedTime?: number, lineId?: string, conversionCurrency?: string, telegramId?: string, allowUsernameChange?: boolean, settings?: { __typename?: 'UserManagementSettings', siteTheme?: string, wallets?: string, loyaltyLandingDisabled?: string, loyaltyLevelNotfDisabled?: string, gameNamesInEnglish?: string, verifyEmailClosed?: string, rtpLiveOnboardingClosed?: string, preferredInGameCurrency?: string, inGameCurrencyOnboardingClosed?: string, hasVisitedBCIO?: string } }, countryInfo?: { __typename: 'UserManagementCountryInfo', id: string, countryCode?: string, blocked?: boolean } };

export const ErrorsFragmentDoc = gql`
    fragment ErrorsFragment on UserManagementError {
  message
  params {
    name
    value
  }
}
    `;
export const UserManagementFragmentDoc = gql`
    fragment UserManagementFragment on UserManagement {
  id
  user: currentUser {
    ...UserFragment
  }
  profile: currentProfile {
    ...ProfileFragment
  }
  countryInfo: currentCountryInfo(site: $site) {
    ...CountryInfoFragment
  }
}
    ${UserFragmentDoc}
${ProfileFragmentDoc}
${CountryInfoFragmentDoc}`;
export const UpdateUserSettingsMutationDocument = gql`
    mutation UpdateUserSettingsMutation($value: String!, $name: UserSettingName!, $site: String) {
  userManagementUpdateUserSettings(input: {name: $name, value: $value}) {
    userManagement {
      ...UserManagementFragment
    }
    errors {
      ...ErrorsFragment
    }
  }
}
    ${UserManagementFragmentDoc}
${ErrorsFragmentDoc}`;

export const getUpdateUserSettingsMutation = async (variables: UpdateUserSettingsMutationVariables) => {
  return fetchGraphql<UpdateUserSettingsMutation, UpdateUserSettingsMutationVariables>(UpdateUserSettingsMutationDocument, "UpdateUserSettingsMutation", { variables });
}