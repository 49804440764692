'use client';
import Cookies from 'js-cookie';
import getGraphqlEndpoint from '@/shared/getGraphqlEndpoint';
import setHeader from '@/shared/utils/setHeader';
import toJson from '@/shared/utils/toJson';

const fetchMutation = async <Mutation, Variables>(
  query: string,
  mutationName: string,
  options?: { variables?: Variables },
) => {
  const url = getGraphqlEndpoint();
  const { variables } = options ?? {};
  const token = Cookies.get('token');
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify({ query, variables }),
    redirect: 'follow',
    headers: {
      ...setHeader(`Authorization`, token, () => `Bearer ${token}`),
      'Content-Type': 'application/json',
    },
  });
  if (!response.ok) {
    return { data: undefined };
  }
  try {
    return toJson<Mutation>(response);
  } catch (error) {
    console.error('graphql request error:', mutationName, error);
    return { data: {} } as { data: Mutation };
  }
};

export default fetchMutation;
