import { create } from 'zustand';

type Props = {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
  setOpenStore: () => void;
  setCloseStore: () => void;
};

const useCashierStore = create<Props>((set) => ({
  isOpen: false,
  setOpen: (isOpen: boolean) => set({ isOpen }),
  setOpenStore: () => set({ isOpen: true }),
  setCloseStore: () => set({ isOpen: false }),
}));

export default useCashierStore;
