import { Button as MoonButton } from '@heathmont/moon-base-tw';
import classNames from '../classNames';

type Props = React.ComponentProps<typeof MoonButton>;

const Button: FC<Props> = ({ children, variant, className, ...props }) => (
  <MoonButton
    customClassName={classNames(
      'text-goten bg-piccolo',
      variant === 'secondary' &&
        'text-bulma bg-transparent ring-inset ring-1 ring-trunks hover:ring-bulma',
      variant === 'tertiary' && 'text-goten bg-hit',
      variant === 'ghost' &&
        'text-goten bg-transparent hover:ring-0 ring-0',
      className,
    )}
    variant={variant}
    {...props}
  >
    {children}
  </MoonButton>
);

export default Button;
