'use client';
import isPathRequestFiltered from './isPathRequestFiltered';

const sendToGtm = (gtmEvent: TrackEventType) => {
  if (isPathRequestFiltered(gtmEvent.pageUrl as string)) {
    return;
  }
  const dataLayer = global.dataLayer;
  if (!dataLayer) {
    return;
  }
  dataLayer.push({ ...gtmEvent, source: 'next-casino' });
};

export default sendToGtm;
