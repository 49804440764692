// ⚠️⚠️⚠️⚠️ DO NOT EDIT ⚠️⚠️⚠️⚠️
/* eslint-disable */

import type * as Types from '../../../schema';

import fetchGraphql from '@/server/helpers/fetchQuery';
import gql from '@/shared/utils/gql';
export type UserQueryVariables = Types.Exact<{
  site: Types.Scalars['String']['input'];
}>;


export type UserQuery = { __typename?: 'Query', features?: { __typename?: 'Features', id: string, walletCurrency?: string }, userManagement?: { __typename?: 'UserManagement', id: string, user?: { __typename: 'UserManagementUser', id: string, email?: string, username?: string, signupTime?: number, authMethods?: Array<string> }, profile?: { __typename: 'UserManagementProfile', _id?: string, id: string, firstName?: string, lastName?: string, maidenName?: string, email?: string, country?: string, phone?: string, affiliateID?: string, city?: string, zip?: string, house?: string, address?: string, state?: string, dateOfBirth?: number, language?: string, hasOtp?: boolean, hasSMS2FA?: boolean, emailVerified?: boolean, passwordChangeDate?: number, contactsRequestedTime?: number, lineId?: string, conversionCurrency?: string, telegramId?: string, allowUsernameChange?: boolean, settings?: { __typename?: 'UserManagementSettings', siteTheme?: string, wallets?: string, loyaltyLandingDisabled?: string, loyaltyLevelNotfDisabled?: string, gameNamesInEnglish?: string, verifyEmailClosed?: string, rtpLiveOnboardingClosed?: string, preferredInGameCurrency?: string, inGameCurrencyOnboardingClosed?: string, hasVisitedBCIO?: string } }, countryInfo?: { __typename: 'UserManagementCountryInfo', id: string, countryCode?: string, blocked?: boolean } } };

export type UserFragment = { __typename: 'UserManagementUser', id: string, email?: string, username?: string, signupTime?: number, authMethods?: Array<string> };

export type ProfileFragment = { __typename: 'UserManagementProfile', _id?: string, id: string, firstName?: string, lastName?: string, maidenName?: string, email?: string, country?: string, phone?: string, affiliateID?: string, city?: string, zip?: string, house?: string, address?: string, state?: string, dateOfBirth?: number, language?: string, hasOtp?: boolean, hasSMS2FA?: boolean, emailVerified?: boolean, passwordChangeDate?: number, contactsRequestedTime?: number, lineId?: string, conversionCurrency?: string, telegramId?: string, allowUsernameChange?: boolean, settings?: { __typename?: 'UserManagementSettings', siteTheme?: string, wallets?: string, loyaltyLandingDisabled?: string, loyaltyLevelNotfDisabled?: string, gameNamesInEnglish?: string, verifyEmailClosed?: string, rtpLiveOnboardingClosed?: string, preferredInGameCurrency?: string, inGameCurrencyOnboardingClosed?: string, hasVisitedBCIO?: string } };

export type CountryInfoFragment = { __typename: 'UserManagementCountryInfo', id: string, countryCode?: string, blocked?: boolean };

export const UserFragmentDoc = gql`
    fragment UserFragment on UserManagementUser {
  __typename
  id
  email
  username
  signupTime
  authMethods
}
    `;
export const ProfileFragmentDoc = gql`
    fragment ProfileFragment on UserManagementProfile {
  __typename
  _id
  id
  firstName
  lastName
  maidenName
  email
  country
  phone
  affiliateID
  city
  zip
  house
  address
  state
  dateOfBirth
  language
  hasOtp
  affiliateID
  hasSMS2FA
  emailVerified
  passwordChangeDate
  contactsRequestedTime
  lineId
  conversionCurrency
  telegramId
  allowUsernameChange
  settings {
    siteTheme: setting(name: siteTheme)
    wallets: setting(name: wallets)
    loyaltyLandingDisabled: setting(name: loyaltyLandingDisabled)
    loyaltyLevelNotfDisabled: setting(name: loyaltyLevelNotfDisabled)
    gameNamesInEnglish: setting(name: gameNamesInEnglish)
    verifyEmailClosed: setting(name: verifyEmailClosed)
    rtpLiveOnboardingClosed: setting(name: rtpLiveOnboardingClosed)
    preferredInGameCurrency: setting(name: preferredInGameCurrency)
    inGameCurrencyOnboardingClosed: setting(name: inGameCurrencyOnboardingClosed)
    hasVisitedBCIO: setting(name: hasVisitedBCIO)
  }
}
    `;
export const CountryInfoFragmentDoc = gql`
    fragment CountryInfoFragment on UserManagementCountryInfo {
  __typename
  id
  countryCode
  blocked
}
    `;
export const UserQueryDocument = gql`
    query UserQuery($site: String!) {
  features {
    id
    walletCurrency: myGroupTag(name: "DepositCurrency")
  }
  userManagement {
    id
    user: currentUser {
      ...UserFragment
    }
    profile: currentProfile {
      ...ProfileFragment
    }
    countryInfo: currentCountryInfo(site: $site) {
      ...CountryInfoFragment
    }
  }
}
    ${UserFragmentDoc}
${ProfileFragmentDoc}
${CountryInfoFragmentDoc}`;

export const getUserQuery = async (variables: UserQueryVariables) => {
  return fetchGraphql<UserQuery, UserQueryVariables>(UserQueryDocument, "UserQuery", { variables });
}