import { create } from 'zustand';

type Props = {
  isMainOpened: boolean;
  setMain: (type: boolean) => void;
  isLoggedOutOpened: boolean;
  setLoggedOut: (type: boolean) => void;
  isLanguageOpened: boolean;
  setLanguage: (type: boolean) => void;
  isMoreOpened: boolean;
  setMore: (type: boolean) => void;
  setCloseAll: () => void;
};

const useBottomNavigationStore = create<Props>((set) => ({
  isMainOpened: false,
  setMain: (type) => set({ isMainOpened: type }),
  isLoggedOutOpened: false,
  setLoggedOut: (type) => set({ isLoggedOutOpened: type }),
  isLanguageOpened: false,
  setLanguage: (type) => set({ isLanguageOpened: type }),
  isMoreOpened: false,
  setMore: (type) => set({ isMoreOpened: type }),
  setCloseAll: () =>
    set({
      isLanguageOpened: false,
      isMainOpened: false,
      isMoreOpened: false,
      isLoggedOutOpened: false,
    }),
}));

export default useBottomNavigationStore;
