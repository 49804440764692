const updateQueryParam = (url: string, name: string, value?: string) => {
  if (url === '') {
    return url;
  }

  const urlObject = new URL(url);
  const params = urlObject.searchParams;

  if (value) {
    params.set(name, value);
  } else {
    params.delete(name);
  }

  return buildPathQuery(urlObject, params);
};

const buildPathQuery = (urlObject: URL, params: URLSearchParams): string => {
  const { hash, pathname } = urlObject;
  const parsedParams = params.toString();
  if (parsedParams) {
    return `${urlObject.origin}${pathname}?${parsedParams}${hash}`;
  }
  return `${urlObject.origin}${pathname}${hash}`;
};

export default updateQueryParam;
