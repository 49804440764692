import { useRouter } from 'next/navigation';
import getDefaultPath from './getDefaultPath/getDefaultPath';
import useCashierStore from '../../../../hooks/useCashierStore';
import updateQueryParam from '@/client/utils/updateQueryParam/updateQueryParam';
import { CashierCurrencyType } from '@/schema';

type Props = {
  cashierPath?: string;
  type: CashierCurrencyType;
  name: CashierCurrency;
};

const useOpenCloseCashier = ({
  cashierPath = 'home-test',
  type,
  name,
}: Props) => {
  const router = useRouter();
  const { isOpen, setOpenStore, setCloseStore } = useCashierStore();

  const handleClose = () => {
    global.history.replaceState(
      global.history.state,
      '',
      updateQueryParam(location.href, 'c'),
    );
    setCloseStore();
    router.refresh();
  };

  const handleOpen = () => {
    const cashierPage = handleCashierPath(cashierPath);

    global.history.replaceState(
      global.history.state,
      '',
      updateQueryParam(location.href, 'c', cashierPage),
    );
    setOpenStore();
  };

  const handleCashierPath = (path = 'home') => {
    if (path.startsWith('desposit_options')) {
      return path;
    }

    switch (path) {
      case 'transfer-out-start':
        return path;
      default:
        return getDefaultPath(cashierPath, type, name);
    }
  };

  return { isOpen, handleOpen, setOpenStore, handleClose };
};

export default useOpenCloseCashier;
