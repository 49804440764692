const CASINO_CDN = 'cdn.coingaming.io';
const CASINO_IMGIX = 'heathmont.imgix.net';
const HUB88_CDN = 'cdn.hub88.io';
const HUB88_IMGIX = 'hub88.imgix.net';
const AWS_S3 = 's3.amazonaws.com';

const mapCdnToImgix = (src: string) =>
  src
    .replace(AWS_S3, '')
    .replace(CASINO_CDN, CASINO_IMGIX)
    .replace(HUB88_CDN, HUB88_IMGIX);

export default mapCdnToImgix;
