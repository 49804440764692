type Params = { args?: Record<string, unknown>; onComplete?: () => void };

const CommsCommands: Record<CommsQueueActions, (params: Params) => void> = {
  show: () => global.commsQueue?.push?.({ action: 'show' }),
  hide: () => global.commsQueue?.push?.({ action: 'hide' }),
  init: ({ args, onComplete }) => {
    try {
      global.commsQueue?.push?.({ action: 'init', args, onComplete });
    } catch (_) {
      CommsCommands.update({ args });
    }
  },
  update: ({ args }) => {
    try {
      return global.commsQueue?.push?.({ action: 'update', args });
    } catch (_) {
      return null;
    }
  },
  terminate: () => global.commsQueue?.push?.({ action: 'terminate' }),
};

export default CommsCommands;
