const LocalStorage = (() => ({
  getItem: (name: string) =>
    typeof window !== 'undefined'
      ? JSON.parse(localStorage.getItem(name) ?? '{}')
      : '',
  setItem: (name: string, value: unknown) =>
    localStorage.setItem(name, JSON.stringify(value)),
  removeItem: (name: string) => localStorage.removeItem(name),
}))();

export default LocalStorage;
