const removeLanguageFromUrl = (pathname: string) => {
  const [, lang, ...paths] = pathname.split('/');
  if (isProperLanguage(lang)) {
    return `/${paths.join('/')}`;
  }
  return pathname;
};

const isProperLanguage = (lang?: string) => lang?.length === 2;

export default removeLanguageFromUrl;
