import parseExternalHref from './parseExternalHref';
import SharedLinkProps from '../../SharedLinkProps';

const PATH_PREFIX = '/casino';

const getParsedHref = (props: SharedLinkProps) => {
  const { href } = props;
  if (href instanceof Object) {
    return { isExternal: false, href };
  }
  const parsedHref = parseExternalHref(props);
  const isExternal = parsedHref.includes('://');
  if (isExternal || parsedHref.includes(PATH_PREFIX)) {
    return { isExternal, href: parsedHref };
  }
  return { isExternal, href: `${PATH_PREFIX}${parsedHref}` };
};

export default getParsedHref;
