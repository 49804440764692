/* eslint-disable immutable/no-mutation */
import isExternalHref from './isExternalHref';
import removeLanguageFromUrl from './removeLanguageFromUrl';

const nativeRedirect = (
  href: string,
  locale: string,
  defaultLocale: string,
) => {
  if (isExternalHref(href)) {
    location.href = href;
    return;
  }
  if (locale === defaultLocale) {
    location.href = `${location.origin}${href}`;
    return;
  }
  location.href = `${location.origin}/${locale}${removeLanguageFromUrl(href)}`;
};

export default nativeRedirect;
