'use client';
/* eslint-disable no-restricted-imports */
import NextLink from 'next/link';
import BridgeSiteLink from './BridgeSiteLink';
import patchHrefLocale from './BridgeSiteLink/helpers/patchHrefLocale';
import SharedLinkProps from './SharedLinkProps';

const Link: FC<SharedLinkProps> = (props) => {
  const { prefetch = false } = props;
  const clonedProps: Partial<SharedLinkProps> = { ...props };
  delete clonedProps.isBridgeSite;
  delete clonedProps.defaultLang;

  if (props.isBridgeSite) {
    return <BridgeSiteLink {...props} prefetch={prefetch} />;
  }
  return (
    <NextLink
      {...clonedProps}
      href={patchHrefLocale(props)}
      locale={false}
      prefetch={prefetch}
    />
  );
};

export default Link;
