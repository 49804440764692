const ignoredPaths = ['/cognito-callback', '/callback#access_token'];

const isPathRequestFiltered = (pageUrl?: string) => {
  if (!pageUrl) {
    return false;
  }
  return ignoredPaths.some((path) => pageUrl.includes(path));
};

export default isPathRequestFiltered;
