import SharedLinkProps from '../../SharedLinkProps';
import isExternalHref from '@/shared/utils/router/isExternalHref';

const patchHrefLocale = ({
  href,
  language,
  defaultLang,
}: Pick<SharedLinkProps, 'href' | 'language' | 'defaultLang'>) => {
  const isDefaultLang = language === defaultLang;
  if (href instanceof Object) {
    if (!isDefaultLang && href.pathname) {
      // eslint-disable-next-line immutable/no-mutation
      href.pathname = addLanguageToPathname(language, href.pathname);
    }
    return href;
  }
  if (isExternalHref(href)) {
    return href;
  }
  return isDefaultLang ? href : addLanguageToPathname(language, href);
};

const addLanguageToPathname = (language: string, pathname: string) =>
  `/${language}${pathname}`;

export default patchHrefLocale;
