/* eslint-disable no-restricted-imports */
import NextLink from 'next/link';
import getParsedHref from './getParsedHref';
import patchHrefLocale from './helpers/patchHrefLocale';
import SharedLinkProps from '../SharedLinkProps';

const BridgeSiteLink: FC<SharedLinkProps> = (props) => {
  const { href, isExternal } = getParsedHref(props);
  const clonedProps: Partial<SharedLinkProps> = { ...props };
  delete clonedProps.isBridgeSite;
  delete clonedProps.defaultLang;
  if (isExternal) {
    return (
      <a
        className={props.className}
        href={href.toString()}
        target={props.target}
      >
        {props.children}
      </a>
    );
  }
  return (
    <NextLink
      {...clonedProps}
      href={patchHrefLocale({ ...props, href })}
      locale={false}
    />
  );
};

export default BridgeSiteLink;
