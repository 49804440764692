const BRIDGE_SITE_PATH_MAP: Record<string, string> = {
  '/profile/funds': '/user/wallet',
  '/profile/deposit': '/user/wallet',
  '/profile/withdraw': '/user/wallet',
  '/profile/transactions': '/user/wallet',
  '/profile/loyalty': '/user/clubhouse',
  '/profile/notifications': '/user/notifications',
  '/profile/account': '/user/account',
  '/loyalty': '/clubhouse',
  '/login': '/id/login',
  '/register': '/id/signup',
  '/help-center/help-your-bonuses/casino-boosts':
    '/help-centre/help-other/help-other-playing-casino/casino-boosts',
};

const replaceMappedPath = (href: string) => {
  const directPath = getDirectPath(href);
  if (directPath) {
    return directPath;
  }
  const pathByPrefix = getPathByPrefix(href);
  if (pathByPrefix) {
    return pathByPrefix;
  }
  return href;
};

const getDirectPath = (href: string) => BRIDGE_SITE_PATH_MAP[href];

const getPathByPrefix = (href: string) => {
  for (const [key, value] of Object.entries(BRIDGE_SITE_PATH_MAP)) {
    if (href.startsWith(key)) {
      return value;
    }
  }
  return undefined;
};

export default replaceMappedPath;
