import { resolveHref } from 'next/dist/client/resolve-href';
import type { Router } from 'next/router';
import replaceMappedPath from './replaceMappedPath';
import SharedLinkProps from '../../SharedLinkProps';
import patchHrefLocale from '../helpers/patchHrefLocale';

const BRIDGE_SITE_PATH_LIST = [
  '/about',
  '/promotions',
  '/news',
  '/vip',
  '/support',
  '/sports',
  '/id',
  '/user',
  '/clubhouse',
  '/help-centre',
];

const parseExternalHref = (props: SharedLinkProps) => {
  const { href, isBridgeSite, domain } = props;
  const parsedHref = resolveHref({} as Router, href);
  if (!isBridgeSite) {
    return parsedHref;
  }
  const mappedHref = replaceMappedPath(parsedHref);
  for (const path of BRIDGE_SITE_PATH_LIST) {
    if (mappedHref.startsWith(path)) {
      const patchedHref = patchHrefLocale({ ...props, href: mappedHref });
      return `https://${domain}${patchedHref}`;
    }
  }
  return mappedHref;
};

export default parseExternalHref;
